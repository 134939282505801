/**
 * Created by Jomo on 2018/06/14.
 */
import {Injectable} from '@angular/core';

@Injectable()
export class AppInterface {
    constructor() {

    }
}

export interface Token {
    access_token:string;
    expires_in:number;
    scope:string;
    token_type:string;
}

export interface UserResponse {
    user:User,
    profile:Profile
}

export interface User {
    userId:string,
    emailAddress:string,
    created:string,
    firstTimeLoggedIn:boolean,
    role:Role,
    active:boolean
}

export interface Profile {
    userId:string,
    name:string,
    phoneNumber:string,
    emailAddress:string,
    created:string,
    active:boolean,
    currency?:string
    companyId?:string
}

export interface Company {
    companyId:string,
    name:string,
    currency:string,
    active:boolean,
    created:string,
    physicalAddress:string,
    physicalCountry:string
}

export interface Role {
    userId:string,
    active:boolean,
    description:string
}

export interface Product {
    name:string,
    productId:string;
    modelNumber:string,
    price:number,
    cost:number,
    moq:number,
    supplierId:string,
    supplierName?:string,
    description?:string,
    userId?:string;
    currency?:string;
    currencySymbol?:string,
    quantity?:string,
    editMode?:boolean,
    productDimensions?:any,
}

export interface Currency {
    symbol:string,
    name:string,
    symbol_native:string,
    decimal_digits:number,
    rounding:number,
    code:string,
    name_plural:string
}

export interface IData<T> {
    reverse:T;
    results:T[];
}