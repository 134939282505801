///<reference path="../../../../node_modules/@angular/router/src/router_state.d.ts"/>
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(public router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {

        // this will be passed from the route config
        //noinspection TypeScriptUnresolvedVariable
        const expectedRoles = route.data.expectedRoles;
        let hasAccess = false;
        const role = localStorage.getItem('role');
        expectedRoles.forEach(_role=>{
            if(role == _role){
                hasAccess = true;
            }
        })
        if (!hasAccess) {
            this.router.navigate(['dashboard']);
            return false;
        }
        return hasAccess;
    }

}