import { HttpClient } from '@angular/common/http';
var Globals = /** @class */ (function () {
    function Globals(httpClient) {
        var _this = this;
        this.httpClient = httpClient;
        //QA Ip Address
        // private _api:string = "http://52.30.160.174/gateway/api";
        // private _auth:string = "http://52.30.160.174/auth/oauth/token?grant_type=client_credentials&client_id=trust_client";
        // private _documentsApi:string = "http://52.30.160.174:3000";
        //local Ip Address
        // private _api: string = "http://localhost:8765/gateway/api";
        // private _api: string = "http://localhost:8080/";
        // private _auth: string = "http://localhost:9099/auth/oauth/token?grant_type=client_credentials&client_id=trust_client";
        // private _auth: string = "http://localhost:9099/auth/generate-token";
        // private _auth: string = "https://www.rhyscy.com:8760/api/authenticate";
        // private _documentsApi: string = "http://localhost:9000";
        //Prod Ip Address
        this._api = "http://www.rhyscy.com/gateway/api";
        this._auth = "https://www.rhyscy.com:9099/auth/generate-token";
        // private _auth: string = "https://www.rhyscy.com:8760/api/authenticate";
        this._documentsApi = "http://www.rhyscy.com:3000";
        // Prod DNS Names
        // private _api:string = "https://www.rhyscy.com/gateway/api";
        // private _auth:string = "https://www.rhyscy.com/auth/oauth/token?grant_type=client_credentials&client_id=trust_client";
        // private _documentsApi:string = "https://www.rhyscy.com:3000";
        this._services = {
            users: "/users",
            usersApiBaseUrl: "https://www.rhyscy.com:8080",
            // usersApiBaseUrl: "http://localhost:8080",
            products: "/products",
            productsApiBaseUrl: "https://www.rhyscy.com:1443",
            // transactions: "/transactions",
            transactions: "/transactions",
            transactionsApiBaseUrl: "https://www.rhyscy.com:8083",
            notifications: "/notifications",
            notificationsApiBaseUrl: "https://www.rhyscy.com:8084"
        };
        this._username = "trust_client";
        this._password = "IDdjJBJ09UOaqB2BXHCz19BWJvaCWRZCwWTbmrfojlE=";
        this.httpClient.get('./assets/files/messages.en.json').subscribe(function (res) {
            _this._messages = res;
        });
    }
    Object.defineProperty(Globals.prototype, "auth", {
        get: function () {
            return this._auth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Globals.prototype, "api", {
        get: function () {
            return this._api;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Globals.prototype, "password", {
        get: function () {
            return this._password;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Globals.prototype, "username", {
        get: function () {
            return this._username;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Globals.prototype, "services", {
        get: function () {
            return this._services;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Globals.prototype, "messages", {
        get: function () {
            return this._messages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Globals.prototype, "documentsApi", {
        get: function () {
            return this._documentsApi;
        },
        enumerable: true,
        configurable: true
    });
    return Globals;
}());
export { Globals };
