import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Globals } from './Globals';
import { Token } from "./app.interface";

@Injectable()
export class ApiService {

    constructor(private _router: Router, private httpClient: HttpClient, private globals: Globals) {
    }

    obtainAccessToken(user: any) {
        // let credentials = this.globals.username + ":" + this.globals.password;
        let credentials = user.emailAddress + ":" + user.password;
        let headers = {
            'Content-Type': 'application/json',
            'username': user.emailAddress,
            'password': user.password,
            'Authorization': btoa(credentials)
        };
        let options = { headers: headers };
        return new Promise((res, error) => {
            //noinspection TypeScriptUnresolvedFunction
            this.httpClient.post(this.globals.auth, null, options)
                .subscribe(
                    response => {
                        const data = <Token>response;
                        ApiService.saveToken(data);
                        res(data);
                    },
                    err => {
                        error(err);
                    });
        })

    }

    static saveToken(token: Token) {
        var expireDate = new Date(token.expires_in);
        console.log("Expire date from Token", expireDate);
        localStorage.setItem("access_token", token.access_token);
        localStorage.setItem("expire_date", expireDate.toLocaleString());
    }


    login(user: any) {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('access_token')
        };

        let options = { headers: headers };
        //noinspection TypeScriptUnresolvedFunction
        return this.httpClient.post('https://www.rhyscy.com:8760/api/login', user, options); //this.globals.api +
        // return this.httpClient.post('http://localhost:8760/api/login', user, options); //this.globals.api +

    }

    resetPassword(user: any) {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        };

        let options = { headers: headers };
        //noinspection TypeScriptUnresolvedFunction
        return this.httpClient.post(this.globals.api + '/users/user/reset-password', user, options);
    }

    getResource(resourceUrl) {
        this.checkCredentials();
        let contentType = 'application/json';
        let auth = 'Bearer ' + localStorage.getItem('access_token');
        let headers = {
            'Content-Type': contentType,
            'Authorization': auth
        };

        let options = { headers: headers };
        // let url = this.globals.api + resourceUrl;
        let url = resourceUrl;

        return new Promise((res, error) => {
            this.httpClient.get(url, options)
                .subscribe(
                    data => {
                        res(data);
                    },
                    err => {
                        if (err.status == 401) {
                            this.logout();
                        }
                        error(err);
                    });
        })
    }

    postPDFResource(resourceUrl, payload: FormData) {
        this.checkCredentials();
        let contentType = 'multipart/form-data';
        let headers = {
            'Content-Type': contentType,
        };

        let options = { headers: headers };
        let url = resourceUrl;
        console.log(payload.get("file"));
        return new Promise((res, error) => {
            //noinspection TypeScriptUnresolvedFunction
            return this.httpClient.post(url, payload, { reportProgress: true, observe: 'events' })
                .subscribe(events => {
                    console.log(events);
                    return events;
                }, err => {
                    console.log("errrrr", err);
                    return err;
                });
        });
    }


    postResource(resourceUrl, payload) {
        this.checkCredentials();

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        };

        let options = { headers: headers };
        // let url = this.globals.api + resourceUrl;
        let url = resourceUrl;

        return new Promise((res, error) => {
            //noinspection TypeScriptUnresolvedFunction
            this.httpClient.post(url, payload, options)
                .subscribe(
                    data => {
                        res(data);
                    },
                    err => {
                        if (err.status == 401) {
                            this.logout();
                        }
                        error(err);
                    });
        })
    }

    putResource(resourceUrl, payload) {
        this.checkCredentials();

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        };

        let options = { headers: headers };
        // let url = this.globals.api + resourceUrl;
        let url = resourceUrl;

        return new Promise((res, error) => {
            //noinspection TypeScriptUnresolvedFunction
            this.httpClient.put(url, payload, options)
                .subscribe(
                    data => {
                        res(data);
                    },
                    err => {
                        if (err.status == 401) {
                            this.logout();
                        }
                        error(err);
                    });
        });
    }

    checkCredentials(): boolean {
        if (!localStorage.getItem('access_token')) {
            this.logout();
            return false;
        } else {
            let expireDate = localStorage.getItem('expire_date');
            // console.log("expire date", expireDate);

            let today = new Date();
            // console.log("today", today);
            // console.log("after conve", new Date(expireDate));
            // if (today < new Date(expireDate)) {
            //     console.log("check expire date");

            //     this.logout();
            //     return false;
            // }
        }
        return true;
    }

    logout() {
        localStorage.clear();
        this._router.navigate(['/login']);
    }

    countries() {
        return this.httpClient.get('./assets/files/Countries.json');
    }

    currencies() {
        return this.httpClient.get('./assets/files/CurrencySign.json');
    }

}

