import { Component, OnInit } from '@angular/core';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {Router} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    message: String;
    constructor(private idle: Idle, private keepalive: Keepalive, private _router: Router) {
        // sets an idle timeout of 5 seconds, for testing purposes.
        idle.setIdle(1);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        idle.setTimeout(3600);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => {
            this.idleState = 'No longer idle.';
            // alert(this.idleState);
        });
        idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            if (localStorage.getItem('access_token') !== null) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('expire_date');
                localStorage.removeItem('name');
                localStorage.removeItem('role');
                localStorage.removeItem('user');
                localStorage.removeItem('profile');
                this._router.navigate(['/login']);
            }
        });
        idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');

        idle.onTimeoutWarning.subscribe((countdown) => {
            // if (countdown === 5) {
            //    // message = 'You will time out in ' + countdown + ' seconds!';
            // }
        });

        // sets the ping interval to 15 seconds
        keepalive.interval(15);

        keepalive.onPing.subscribe(() => this.lastPing = new Date());

        this.reset();
    }
    reset() {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
      }

    ngOnInit() {
    }
}
