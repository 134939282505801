import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class Globals {

    constructor(private httpClient: HttpClient) {
        this.httpClient.get('./assets/files/messages.en.json').subscribe(
            res => {
                this._messages = <any>res;
            }
        )
    }

    //QA Ip Address
    // private _api:string = "http://52.30.160.174/gateway/api";
    // private _auth:string = "http://52.30.160.174/auth/oauth/token?grant_type=client_credentials&client_id=trust_client";
    // private _documentsApi:string = "http://52.30.160.174:3000";

    //local Ip Address
    // private _api: string = "http://localhost:8765/gateway/api";
    // private _api: string = "http://localhost:8080/";
    // private _auth: string = "http://localhost:9099/auth/oauth/token?grant_type=client_credentials&client_id=trust_client";
    // private _auth: string = "http://localhost:9099/auth/generate-token";
    // private _auth: string = "https://www.rhyscy.com:8760/api/authenticate";
    // private _documentsApi: string = "http://localhost:9000";

    //Prod Ip Address
    private _api: string = "http://www.rhyscy.com/gateway/api";
    private _auth: string = "https://www.rhyscy.com:9099/auth/generate-token";
    // private _auth: string = "https://www.rhyscy.com:8760/api/authenticate";
    private _documentsApi: string = "http://www.rhyscy.com:3000";

    // Prod DNS Names
    // private _api:string = "https://www.rhyscy.com/gateway/api";
    // private _auth:string = "https://www.rhyscy.com/auth/oauth/token?grant_type=client_credentials&client_id=trust_client";
    // private _documentsApi:string = "https://www.rhyscy.com:3000";

    private _services: any = {
        users: "/users",
        usersApiBaseUrl: "https://www.rhyscy.com:8080",
        // usersApiBaseUrl: "http://localhost:8080",
        products: "/products",
        productsApiBaseUrl: "https://www.rhyscy.com:1443",
        // transactions: "/transactions",
        transactions: "/transactions",
        transactionsApiBaseUrl: "https://www.rhyscy.com:8083",
        notifications: "/notifications",
        notificationsApiBaseUrl: "https://www.rhyscy.com:8084"
    };

    private _username: string = "trust_client";
    private _password: string = "IDdjJBJ09UOaqB2BXHCz19BWJvaCWRZCwWTbmrfojlE=";

    private _messages: any;


    get auth(): string {
        return this._auth;
    }

    get api(): string {
        return this._api;
    }

    get password(): string {
        return this._password;
    }

    get username(): string {
        return this._username;
    }

    get services(): any {
        return this._services;
    }

    get messages(): any {
        return this._messages;
    }

    get documentsApi(): string {
        return this._documentsApi;
    }

}
